import React, { Fragment } from 'react';
import Container from 'components/shared/Container';
import { StyledSectionHeader, Header } from './SectionHeader.styled';

const SectionHeader = ({ sectionText, children, id }) => {
   return (
      <Container id={id}>
         <StyledSectionHeader>
            <Header childrenExist={children}>
               {sectionText.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </Header>

            {children}
         </StyledSectionHeader>
      </Container>
   );
};
const MemoHeader = React.memo(SectionHeader);
export default MemoHeader;
