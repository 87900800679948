import React, { Fragment } from 'react';
import {
   StyledHeaderWrapper,
   StyledPageHeader,
   SubHeader,
} from './PageHeader.styled';
import Container from 'components/shared/Container';

const PageHeader = ({ headerText, children, as, subHeader }) => {
   return (
      <Container as={as}>
         <StyledHeaderWrapper>
            {subHeader && <SubHeader>{subHeader}</SubHeader>}
            <StyledPageHeader>
               {headerText.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </StyledPageHeader>
            {children}
         </StyledHeaderWrapper>
      </Container>
   );
};
export default React.memo(PageHeader);
