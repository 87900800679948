import styled from 'styled-components';
import { breakpoints } from 'variables';

export const StyledSectionHeader = styled.header`
   text-align: center;
   margin-top: 50px;

   button {
      margin: 15px auto 0;
   }

   ${breakpoints.lg`
      margin-top: 100px;
      padding: 0 125px;
   `}
`;

export const Header = styled.h2`
   line-height: 36px;
   ${breakpoints.lg`
      line-height: 60px;
      margin-bottom: ${({ childrenExist }) => (childrenExist ? '20px' : null)};
   `}
`;
