import styled from 'styled-components';
import { colors, breakpoints } from 'variables';

export const SubHeader = styled.p`
   display: inline-block;
   margin-bottom: 15px;
   font-size: 1.3rem;
   line-height: 2.8rem;
   text-transform: uppercase;
   position: relative;
   font-weight: 600;
   padding-bottom: 12px;
   ::after {
      border-top: 2px solid ${colors.brand};
      border-radius: 30%;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 10px;
      position: absolute;
   }
   ${breakpoints.md`
      font-size: 1.6rem;
      margin-bottom: 20px;
   `}
`;

export const StyledHeaderWrapper = styled.div`
   text-align: center;
   margin: 100px 0 0;
   ${breakpoints.lg`
      margin: 170px 0 0;
      padding: 0 70px;
      p { line-height: 28px; }
      `}
`;

export const StyledPageHeader = styled.h1`
   font-weight: 200;
   line-height: 34px;
   color: ${colors.black};
   strong {
      font-weight: 700;
   }
   p {
      line-height: initial;
      margin: 0;
   }
   ${breakpoints.lg`
      line-height: 74px;
   `}
`;
