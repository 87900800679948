import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, image, article }) => {
   const { pathname } = useLocation();
   const { site } = useStaticQuery(query);
   const {
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteUrl,
      defaultImage,
      webclip,
   } = site.siteMetadata;
   const seo = {
      title: title || defaultTitle,
      description: description || defaultDescription,
      image: `${siteUrl}${image || defaultImage}`,
      url: `${siteUrl}${pathname}`,
      webclip: webclip,
   };
   return (
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
         <meta name="description" content={seo.description} />
         <meta name="image" content={seo.image} />
         <link rel="apple-touch-icon" href={seo.webpclip} />
         <link rel="apple-touch-icon" sizes="76x76" href={seo.webpclip} />
         <link rel="apple-touch-icon" sizes="120x120" href={seo.webpclip} />
         <link rel="apple-touch-icon" sizes="152x152" href={seo.webpclip} />
         <meta property="fb:app_id" content="320063498627957" />
         {seo.url && <meta property="og:url" content={seo.url} />}
         <meta property="og:type" content="website" />
         {seo.title && (
            <meta property="og:title" content={seo.title + ' · LabControl'} />
         )}
         {seo.description && (
            <meta property="og:description" content={seo.description} />
         )}
         {seo.image && <meta property="og:image" content={seo.image} />}
         <meta name="twitter:card" content="summary_large_image" />
         {seo.title && <meta name="twitter:title" content={seo.title} />}
         {seo.description && (
            <meta name="twitter:description" content={seo.description} />
         )}
         {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
   );
};
export default SEO;
SEO.propTypes = {
   title: PropTypes.string,
   description: PropTypes.string,
   image: PropTypes.string,
   article: PropTypes.bool,
};
SEO.defaultProps = {
   title: null,
   description: null,
   image: null,
   article: false,
};
const query = graphql`
   query SEO {
      site {
         siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
         }
      }
   }
`;
