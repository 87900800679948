import arrow_right from 'assets/icons/arrow_right_24.svg';
import routes from 'data/globals/routes';
import React from 'react';
import PackageCard from './PackageCard';
import {
   MoreInformation,
   PackagesContainer,
   StyledContainer,
   StyledLink,
} from './PackagesSection.styled';

const PackagesSection = ({ packagesLinks }) => {
   const sectionLink = packagesLinks.link;

   const packages = [
      {
         name: 'Student',
         price: '0 zł',
         description: [
            'Wersja Student to ',
            <strong>podstawowe funkcjonalności systemu </strong>,
            'zaprojektowane dla jednostek naukowych i szkół.',
         ],
         features: [
            'Tworzenie własnych szablonów ',
            'Wprowadzanie wyników ',
            'Zapis historii wyników',
         ],
         featured: false,
         testPeriod: '10 miesięczna darmowa licencja',
      },
      {
         name: 'Test&Go',
         price: ['od 999 zł'],
         isMonthly: true,
         description: [
            'Wersja Test&GO to ',
            <strong>pełny zakres funkcjonalności</strong>,
            ' systemu dedykowany przedsiębiorstwom różnego typu.',
         ],
         packageInclude: 'Zawiera wszystko z wersji Student',
         features: [
            'Tworzenie własnych szablonów',
            'Wprowadzanie wyników',
            'Przydzielanie osób do zadań',
            'Szybki eksport raportów',
         ],
         featured: true,
         testPeriod: '2 tygodnie darmowe',
      },
      {
         name: 'Rocket',
         price: 'Skontaktuj się',
         description: [
            'Rocket to rozwiązanie uwzględniające ',
            <strong>indywidualne potrzeby klienta.</strong>,
         ],
         packageInclude: 'Zawiera wszystko z wersji Test&go',
         features: [
            'Audyt biznesowy procesów',
            'Funkcjonalności szyte na miarę',
            'Zastosowanie kodów kreskowych/QR',
         ],
         featured: false,
      },
   ];

   packagesLinks.buttons.forEach((button, i) => (packages[i].button = button));

   const packagesList = packages.map((card, index) => (
      <PackageCard key={index} cardInfo={card} />
   ));

   return (
      <StyledContainer>
         <PackagesContainer>{packagesList}</PackagesContainer>

         <MoreInformation>
            {sectionLink.to.startsWith('/') ? (
               <StyledLink to={`${sectionLink.to}#pakiety`}>
                  {sectionLink.text}
                  <img src={arrow_right} alt="arrow" />
               </StyledLink>
            ) : (
               <StyledLink to={`${routes.pricing}#pakiety`} $rotateArrow={true}>
                  {sectionLink.text}
                  <img src={arrow_right} alt="arrow" />
               </StyledLink>
            )}
         </MoreInformation>
      </StyledContainer>
   );
};

export default PackagesSection;
