import React from 'react';
import { IndicatorContainer, IndicatorText } from './ArrowIndicator.styled';

import indicatorArrow from 'assets/Arrow_indicator.svg';

const ArrowIndicator = ({ children }) => {
   return (
      <IndicatorContainer>
         <IndicatorText>{children}</IndicatorText>
         <img src={indicatorArrow} alt="arrow" />
      </IndicatorContainer>
   );
};
const MemoArrow = React.memo(ArrowIndicator);
export default MemoArrow;
