import styled from 'styled-components';
import { colors, breakpoints } from 'variables';

export const IndicatorContainer = styled.div`
   display: flex;
   width: 100%;
   justify-content: center;
   margin: 10px 0 15px;
   flex-direction: column;
   align-items: center;

   ${breakpoints.md`
      margin: 30px 0 0;
   `}

   ${breakpoints.lg`
      flex-direction: row;
      align-items: initial;
   `}
`;

export const IndicatorText = styled.h3`
   font-family: 'Kaushan Script', cursive;
   font-size: 2rem;
   color: ${colors.brand};
   transform: rotate(-4deg);
   margin: 0 0 20px 0;
   width: max-content;

   ${breakpoints.lg`
      margin: 0 15px 20px 0;
   `}
`;
