import styled from 'styled-components';
import Container from 'components/shared/Container';
import { breakpoints } from 'variables';
import { colors } from 'styles/variables';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const StyledContainer = styled(Container)`
   flex-direction: column;
   align-items: center;
`;

export const PackagesContainer = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;
   flex-direction: column;

   ${breakpoints.lg`
      flex-direction: row;
   `}
`;

export const MoreInformation = styled.p`
   font-size: 1.5rem;
   font-weight: 600;
   margin-top: 50px;

   img {
      margin-left: 10px;
   }
   a:hover {
      color: ${colors.brand};
   }

   ${breakpoints.lg`
      font-size: 1.8rem;
   `}
`;

export const StyledLink = styled(AnchorLink)`
   display: flex;
   align-items: center;
   font-weight: 600;
   :hover {
      color: ${colors.brand};
   }

   img {
      transform: ${({ $rotateArrow }) =>
         $rotateArrow ? 'rotate(90deg)' : null};
   }
`;
